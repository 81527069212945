import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { deleteUser, resendInvite } from "../../../client/actions/apiActions";
import { confirmAlert } from "react-confirm-alert";

const UserRow = (props) => {
  const { user, setSuccessMessage } = props;

  const resendInvite = (userId) => {
    props.resendInvite(userId);
  };

  const deleteUser = (userId, userName) => {
    const deleteOptions = {
      title: "Confirm Delete",
      message: `Are you sure you wish to delete this user "${userName}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.deleteUser(userId);
            setSuccessMessage("User has been deleted!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    };
    confirmAlert(deleteOptions);
  };

  return (
    <tr>
      <td nowrap="true" className="align-middle">
        - - {user.name} &nbsp;
      </td>
      <td nowrap="true" className="align-middle">
        {user.phone} &nbsp;
      </td>
      <td nowrap="true" className="align-middle">
        {user.email} &nbsp;
      </td>
      {user.accountLocked === "1" && (
        <td nowrap="true" className="align-middle text-center">
          <i className="fas fa-check-circle text-danger" />
        </td>
      )}
      {user.accountLocked !== "1" && (
        <td nowrap="true" className="align-middle text-center">
          <i className="fas fa-times-circle text-secondary" />
        </td>
      )}
      <td nowrap="true" className="text-right">
        {user.password === 0 && (
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => resendInvite(user.id)}
            title="Resend Invite Email"
          >
            <i className="fas fa-envelope" />
          </button>
        )}
        <Link
          className="btn btn-primary btn-sm m-1"
          to={"/clients/user_association/" + user.id}
        >
          <i className="fas fa-users" />
        </Link>
        {user.type === "Company" && (
          <Link
            className="btn btn-warning btn-sm m-1"
            to={"/clients/client_edit/" + user.id}
          >
            <i className="fas fa-pencil-alt" />
          </Link>
        )}
        {user.type === "User" && (
          <Link
            className="btn btn-warning btn-sm m-1"
            to={"/clients/user_edit/" + user.id}
          >
            <i className="fas fa-pencil-alt" />
          </Link>
        )}

        <button
          className="btn btn-danger btn-sm m-1"
          onClick={() => deleteUser(user.id, user.name + " " + user.email)}
        >
          <i className="fas fa-times" />
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.pages.clientList.userRole,
  userInfo: state.pages.clientList.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  deleteUser,
  resendInvite,
})(UserRow);
