import {
  GET_DATA,
  GET_DATA_LIST,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  GET_CONFIG,
  SAVE_CONFIG,
  GET_PROFILE,
  SAVE_PROFILE,
  GET_CLIENTS,
  GET_ACTIVE_CLIENTS,
  GET_CLIENT,
  SAVE_CLIENT,
  DELETE_CLIENT,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  GET_LIST,
  SEND_INVITE,
  SAVE_COMPANY,
  GET_COMPANY,
  DELETE_USER,
  SAVE_USER,
  GET_USER,
  GET_SEARCH_COMPANIES,
  DELETE_ASSOCIATION,
  SAVE_ASSOCIATION,
  GET_ASSOCIATIONS,
} from "./types";
import axios from "axios";

import { API_URL } from "../../config";

export const sendUserInvite =
  (
    inviteEmail,
    inviteCompany,
    firstName,
    lastName,
    userRole,
    customersChecked,
    foldersChecked,
  ) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/sendUserInvite`,
        {
          inviteEmail,
          inviteCompany,
          firstName,
          lastName,
          userRole,
          customersChecked,
          foldersChecked,
        },
        tokenConfig(getState),
      );
      dispatch({ type: SEND_INVITE, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getList =
  (tableName, orderByField) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/getList`,
        { tableName, orderByField },
        tokenConfig(getState),
      );
      dispatch({ type: GET_LIST, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };

// CONFIG SETTINGS
export const saveConfigSettings =
  (loginDisclaimerMessage, dashboardContent, archivedBusinessMessage) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveConfigSettings`,
        {
          loginDisclaimerMessage: loginDisclaimerMessage,
          dashboardContent: dashboardContent,
          archivedBusinessMessage: archivedBusinessMessage,
        },
        tokenConfig(getState),
      );
      dispatch({
        type: SAVE_CONFIG,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getConfigSettings = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getConfigSettings`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CONFIG,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// PROFILE SETTINGS
export const saveProfileSettings =
  (firstName, lastName, phone) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveProfileSettings`,
        { firstName, lastName, phone },
        tokenConfig(getState),
      );
      dispatch({
        type: SAVE_PROFILE,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const saveProfileNotifications =
  (newFileNotifications, newMessageNotifications, newSignatureRequest) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveProfileNotifications`,
        { newFileNotifications, newMessageNotifications, newSignatureRequest },
        tokenConfig(getState),
      );
      dispatch({
        type: SAVE_PROFILE,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const saveProfilePassword =
  (password, confirmPassword, currentPassword) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveProfilePassword`,
        { password, confirmPassword, currentPassword },
        tokenConfig(getState),
      );
      dispatch({
        type: SAVE_PROFILE,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getProfileSettings = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getProfileSettings`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// PROFILE COMPANY
export const saveProfileCompany = (saveData) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/saveProfileCompany`,
      { saveData },
      tokenConfig(getState),
    );
    dispatch({ type: SAVE_COMPANY, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};
export const getProfileCompany = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getProfileCompany`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// COMPANIES
export const getClients = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/getClients`, tokenConfig(getState));
    dispatch({
      type: GET_CLIENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getActiveClients = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getActiveClients`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_ACTIVE_CLIENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getClient = (userId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getClients/${userId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CLIENT,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveClient =
  (id, companyName, active, folderSort) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveClient`,
        { id, companyName, active, folderSort },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_CLIENT, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };
export const deleteClient = (clientId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteClient`,
      { clientId },
      tokenConfig(getState),
    );
    dispatch({ type: DELETE_CLIENT, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};
export const activateClient = (clientId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/activateClient`,
      { clientId },
      tokenConfig(getState),
    );
    dispatch({ type: DELETE_CLIENT, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};

// EMAIL TEMPLATES
export const getEmailTemplates = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getEmailTemplates`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_EMAIL_TEMPLATES,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getEmailTemplate = (templateId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getEmailTemplates/${templateId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_EMAIL_TEMPLATE,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveEmailTemplate =
  (id, message, subject, fromName, fromEmail, name) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveEmailTemplate`,
        { id, message, subject, fromName, fromEmail, name },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_EMAIL_TEMPLATE, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };
export const deleteEmailTemplate =
  (templateId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/deleteEmailTemplate`,
        { templateId },
        tokenConfig(getState),
      );
      dispatch({ type: DELETE_EMAIL_TEMPLATE, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };

// EMAIL TEMPLATES
export const getDefaultFolders = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getDefaultFolders`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_EMAIL_TEMPLATES,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getDefaultFolder = (folderId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getDefaultFolders/${folderId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_EMAIL_TEMPLATE,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveDefaultFolder =
  (id, folderName, parentFolder, folderType) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveDefaultFolder`,
        { id, folderName, parentFolder, folderType },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_EMAIL_TEMPLATE, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };
export const deleteDefaultFolder = (folderId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteDefaultFolder`,
      { folderId },
      tokenConfig(getState),
    );
    dispatch({ type: DELETE_EMAIL_TEMPLATE, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};

// USERS ASSOCIATIONS
export const searchCompanies =
  (searchKeyword) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/searchCompanies`,
        { searchKeyword },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_SEARCH_COMPANIES,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getAssociations = (userId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getAssociations/${userId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_ASSOCIATIONS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const deleteAssociation =
  (userId, associationId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/deleteAssociation`,
        { userId, associationId },
        tokenConfig(getState),
      );
      dispatch({ type: DELETE_ASSOCIATION, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };
export const saveAssociation =
  (userId, newAssociationId, associationType) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveAssociation`,
        { userId, newAssociationId, associationType },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_ASSOCIATION, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };

// USERS
export const getUser = (userId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getUser/${userId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const getCreateUser = (companyId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getCreateUser/${companyId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteUser`,
      { userId },
      tokenConfig(getState),
    );
    dispatch({ type: DELETE_USER, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveUser =
  (
    id,
    firstName,
    lastName,
    email,
    phone,
    administrator,
    accountLocked,
    userRole,
    customersChecked,
    foldersChecked,
  ) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveUser`,
        {
          id,
          firstName,
          lastName,
          email,
          phone,
          administrator,
          accountLocked,
          userRole,
          customersChecked,
          foldersChecked,
        },
        tokenConfig(getState),
      );
      dispatch({ type: SAVE_USER, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };

// USER INVITES
export const getInvite = (inviteId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getInvite/${inviteId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const resendInvite = (userId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/resendInvite/${userId}`,
      tokenConfig(getState),
    );
    dispatch({ type: GET_DATA, payload: res.data });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveUserInvite =
  (inviteToken, firstName, lastName, email, phone, password, confirmPassword) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/saveUserInvite`,
        {
          inviteToken,
          firstName,
          lastName,
          email,
          phone,
          password,
          confirmPassword,
        },
        tokenConfig(getState),
      );
      dispatch({ type: GET_PROFILE, payload: res.data });
    } catch (e) {
      console.log("e", e);
    }
  };

// DOCUMENT CENTER
export const getDocuments = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getDocuments`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getFileCabinet = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getFileCabinet`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const deleteFile = (fileId, clientId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteFile`,
      { fileId, clientId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const moveFile =
  (clientId, fileId, folderId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/fileMove`,
        { clientId, fileId, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const archiveFile = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/archiveFile`,
      { fileId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getFileDetails = (fileId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/getFileDetails`,
      { fileId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DOCUMENT,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const getUserRoles = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getUserRoles`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const deleteUserRole = (roleId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteUserRole`,
      { id: roleId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getUserRole = (roleId) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/getUserRole`,
      { id: roleId },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveUserRole = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/saveUserRole`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// DOCUMENTS
export const getDocumentClients = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getDocumentClients`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_CLIENTS,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const syncFolders = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/syncFolders`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA_LIST,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const getClientFolders =
  (clientId, sortDir, sortField) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/getClientFolders`,
        { clientId, sortDir, sortField },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const getClientFiles =
  (clientId, fullPath, sortDir, sortField, folderId) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/getClientFiles`,
        { clientId, fullPath, sortDir, sortField, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DATA_LIST,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const deleteFolder =
  (clientId, folderId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${API_URL}/deleteFolder`,
        { clientId, folderId },
        tokenConfig(getState),
      );
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };

// CUSTOM FOLDER SORTING
export const saveSortingName = (id, name) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/saveSortingName`,
      { id, name },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const deleteSortingName = (id) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/deleteSortingName`,
      { id },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const loadSortingName = (sortFolderId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/loadSortingName?sortFolderId=${sortFolderId}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const loadSortingNameList = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/loadSortingNameList`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const getSortFolders = (id) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/getSortFolders?id=${id}`,
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};
export const saveSortFolders = (id, folders) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/saveSortFolders`,
      { id, folders },
      tokenConfig(getState),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (e) {
    console.log("e", e);
  }
};

// CONFIG
export const tokenConfig = (getState) => {
  // GET TOKEN FROM STATE
  const token = getState().auth.token;

  // HEADERS
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // IF TOKEN ADD TO HEADERS
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};
