import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { saveClient } from "../../../client/actions/apiActions";
import ItemText from "../../component/itemText";
import Toggle from "react-toggle";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class ClientsAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      companyName: "",
      active: true,
      errors: {},
      successMessage: "",
      errorMessage: "",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.clientList.status === 0) {
        this.setState({ errorMessage: this.props.clientList.message });
      }
      if (this.props.clientList.status === 1) {
        this.props.router.navigate("/clients");
      }
    }
  }

  static propTypes = {
    saveClient: PropTypes.func.isRequired,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveClient = () => {
    const { companyName, active } = this.state;
    let noerror = true;
    let errors = {};
    if (companyName === "") {
      noerror = false;
      errors = {
        ...errors,
        companyName: 'You left the users "Company Name" blank',
      };
    }
    if (noerror === true) {
      this.props.saveClient("0", companyName, active);
    } else {
      this.setState({ errors, isSaving: false });
    }
  };
  render() {
    const { companyName, active, successMessage, errorMessage, errors } =
      this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 m-auto">
            <div className="card card-body mt-5 ">
              <h1 className="text-center my-4">Add New Company</h1>
              <hr noshade="true" />
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}

              <ItemText
                errors={errors.companyName}
                label="Company Name"
                type="text"
                id="companyName"
                value={companyName}
                placeholder="enter company name"
                onChange={this.onChange}
              />

              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="newSignatureRequest"
                >
                  Active
                </label>
                <div className="col-sm-8">
                  <Toggle
                    id="active"
                    name="active"
                    defaultChecked={active}
                    onChange={this.hangleToggleChange}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-success my-2"
                onClick={() => this.saveClient()}
              >
                <i className="fas fa-save" /> SAVE
              </button>
              <Link className="btn btn-secondary my-2" to="/clients">
                <i className="fas fa-ban" /> CANCEL
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dropdownList: state.pages.dropdownList,
  saveData: state.pages.saveData,
  clientList: state.pages.clientList,
});
export default compose(
  withRouter,
  connect(mapStateToProps, { saveClient }),
)(ClientsAdd);
